<template>
  <div class="mainContent liftMonitor">
    <div class="header">
      <img class="large" src="../../../public/img/logo_b_volks.png" style="width:140px;height:40px"/>
      <div>电梯监控 /</div>
      <div>单梯监控 / </div>
      <div>{{ elevatorInfo.elevator.no }}</div>
    </div>
    <div class="main">
      <!-- 模型图 -->
      <div class="mainLeft">
        <div class="elevatorName">{{ elevatorInfo.elevator.name }}</div>
        <div class="model">
          <div class="modelImg">
            <img class="large" src="../../assets/elevator.png" style="max-width: 100%;max-height: 100%;"/>
          </div>
        </div>
        <div :class="['toggle', toggleModalShow ? 'toggleActive' : '']" @click="toggleModalShow = !toggleModalShow">
          切换
        </div>
        <div v-if="toggleModalShow" class="toggleModal">
          <ul>
            <li v-for="item in useUnitElevators" :key="item.id"
              :style="{ color: item.id === elevatorInfo.elevator.id ? '#0747fd' : '' }"
              @click="changeElevator(item.id)">
              {{ item.name + ' (' + item.no + ')' }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 电梯信息 -->
      <div class="mainRight">
        <div class="mainRightContent">
          <div class="contentTop">
            <div class="contentLeft">
              <!-- 实时数据 -->
              <div class="liveData module">
                <div class="moduleTitle">
                  <div class="leftLine"></div>
                  <div class="title">实时数据</div>
                  <div class="right">
                    <div v-if="connectState === '连接正常'">
                      <i class="el-icon-link"></i>
                      <span style="margin-left:3px">{{ connectState }}</span>
                    </div>
                    <div v-else-if="connectState === '连接断开'" style="color:#FFB22C">
                      <i class="el-icon-paperclip"></i>
                      <span style="margin-left:3px">{{ connectState }}</span>
                    </div>
                    <div v-else>{{ connectState }}</div>
                  </div>
                </div>
                <div class="moduleContent">
                  <div class="liveDataItem">
                    <div class="liveDataTitle">运行状态</div>
                    <div class="liveDataContent">
                      <span v-if="webSocketInfo.Ele_Run_State === 0">停止</span>
                      <span v-else-if="webSocketInfo.Ele_Run_State === 1">运行</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">当前楼层</div>
                    <div class="liveDataContent">
                      {{ webSocketInfo.Curr_Floor ? webSocketInfo.Curr_Floor : '-' }}
                    </div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">运行方向</div>
                    <div class="liveDataContent">
                      <div v-if="webSocketInfo.Ele_Run_State === 0">
                        <div v-if="webSocketInfo.Ele_Run_Trend === 0" style="display:flex;align-items: center;">
                          <img src="/static/images/elevator/up1.png"
                            style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>上行</span>
                        </div>
                        <div v-else-if="webSocketInfo.Ele_Run_Trend === 1" style="display:flex;align-items: center;">
                          <img src="/static/images/elevator/down1.png"
                            style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>下行</span>
                        </div>
                        <div v-else>-</div>
                      </div>
                      <div v-else>
                        <div v-if="webSocketInfo.Ele_Run_Trend === 0" style="display:flex;align-items: center;">
                          <img src="../../assets/up.gif" style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>上行</span>
                        </div>
                        <div v-else-if="webSocketInfo.Ele_Run_Trend === 1" style="display:flex;align-items: center;">
                          <img src="../../assets/down.gif" style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>下行</span>
                        </div>
                        <div v-else>-</div>
                      </div>
                    </div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">运行速度</div>
                    <div class="liveDataContent">
                      {{ currentSpeed ? (currentSpeed / 100).toFixed(2) + 'm/s' : '-' }}
                    </div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">门状态</div>
                    <div class="liveDataContent">
                      <span v-if="webSocketInfo.Ele_Car_Status === 0">开门</span>
                      <span v-else-if="webSocketInfo.Ele_Car_Status === 1">关门</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <div class="liveDataItem borderBottomNone">
                    <div class="liveDataTitle floorAction">上召</div>
                    <div class="liveDataContent">
                      <span v-for="item in webSocketInfo.Outer_Up_Orders" :key="item" class="floor">{{ item }}</span>
                    </div>
                  </div>
                  <div class="liveDataItem borderBottomNone">
                    <div class="liveDataTitle floorAction">下召</div>
                    <div class="liveDataContent">
                      <span v-for="item in webSocketInfo.Outer_Down_Orders" :key="item" class="floor">{{ item }}</span>
                    </div>
                  </div>
                  <div class="liveDataItem borderBottomNone">
                    <div class="liveDataTitle floorAction">轿内</div>
                    <div class="liveDataContent">
                      <span v-for="item in webSocketInfo.Inner_Orders" :key="item" class="floor">{{ item }}</span>
                    </div>
                  </div>
                  <!-- 重新连接 -->
                  <div v-if="connectState === '连接断开'" class="reconnect">
                    <div class="reconnectBtn" @click="createWs()">重新连接</div>
                  </div>
                </div>
              </div>
              <!-- 电梯状态 -->
              <div class="elevatorStatus module">
                <div class="moduleTitle">
                  <div class="leftLine"></div>
                  <div class="title">电梯状态</div>
                </div>
                <div class="moduleContent">
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="0" disabled>停止服务</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Unlock" :label="0" disabled>开锁区域</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="1" disabled>正常运行</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Passenger" :label="1" disabled>轿内有人</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="2" disabled>检修</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Over_Load" :label="2" disabled>超载</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="3" disabled>紧急电动运行控制</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Over_Load" :label="1" disabled>满载</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="4" disabled>火灾返回</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Door_Open_Status" :label="0" disabled>开门到位</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="5" disabled>消防员服务</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Door_Close_Status" :label="0" disabled>关门到位</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="6" disabled>紧急电源运行</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Hall_Status" :label="0" disabled>厅门开</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="7" disabled>地震模式</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Hall_Status" :label="1" disabled>厅门关</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="8" disabled>其他</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Car_Status" :label="0" disabled>轿门开</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Status" :label="0" disabled>故障</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Car_Status" :label="1" disabled>轿门关</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Trac_Mac" :label="1" disabled>制动器提起</el-radio>
                  <el-radio v-model="webSocketInfo.Ele_Trac_Mac" :label="0" disabled>制动器释放</el-radio>
                </div>
              </div>
            </div>
            <!-- 电梯概况到故障记录 -->
            <div class="contentRight module">
              <div class="moduleTitle">
                <div :class="[currentTab === 1 ? 'currentTab' : '']" @click="currentTab = 1">电梯概况</div>
                <div :class="[currentTab === 2 ? 'currentTab' : '']" @click="currentTab = 2">运行参数</div>
              </div>
              <!-- 电梯概况 -->
              <div v-if="currentTab === 1" class="moduleContent">
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯名称</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.name ? elevatorInfo.elevator.name : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯编号</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.no ? elevatorInfo.elevator.no : '-' }}</div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">注册(设备)代码</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.regNo ? elevatorInfo.elevator.regNo : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯品牌</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.brand ? elevatorInfo.elevator.brand : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯规格型号</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.model ? elevatorInfo.elevator.model : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">梯种</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.elevatorProductTypeName ?
                    elevatorInfo.elevator.elevatorProductTypeName : '-' }}</div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">下次年检时间</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.nextYearCheckDate ? elevatorInfo.nextYearCheckDate :
                    '-'
                    }}</div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">下次维保时间</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.nextMaintRecordDate ? elevatorInfo.nextMaintRecordDate
                    :
                    '-'
                    }}</div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯地址</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.address ? elevatorInfo.elevator.address : '-'
                    }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">使用单位</div>
                  <div class="elevatorInfoRight">{{ elevatorInfo.elevator.useUnitName ?
                    elevatorInfo.elevator.useUnitName
                    : '-' }}</div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">维保企业</div>
                  <div class="elevatorInfoRight">{{
                    elevatorInfo.elevator.maintComName ? elevatorInfo.elevator.maintComName : '-' }}</div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">联系人</div>
                  <div class="elevatorInfoRight">{{
                    elevatorInfo.elevator.maintEmpName ? elevatorInfo.elevator.maintEmpName : '-' }}</div>
                </div>
              </div>
              <!-- 运行参数 -->
              <div v-if="currentTab === 2" class="moduleContent runParams">
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯运行次数</div>
                  <div class="elevatorInfoRight">
                    {{ webSocketInfo.Run_Times !== -1 ? webSocketInfo.Run_Times + '次' : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯运行时间</div>
                  <div class="elevatorInfoRight">
                    {{ webSocketInfo.Run_Time ? getHM(webSocketInfo.Run_Time * 60) : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯开关门次数</div>
                  <div class="elevatorInfoRight">
                    {{ webSocketInfo.Run_Door !== -1 ? webSocketInfo.Run_Door + '次' : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">钢丝绳折弯次数</div>
                  <div class="elevatorInfoRight">
                    {{ webSocketInfo.Run_Wire_Rope !== -1 ? webSocketInfo.Run_Wire_Rope + '次' : '-' }}
                  </div>
                </div>
                <div class="elevatorInfo">
                  <div class="elevatorInfoLeft">电梯运行距离</div>
                  <div class="elevatorInfoRight">
                    {{ webSocketInfo.Run_Distance ? (webSocketInfo.Run_Distance / 1000).toFixed(2) + '千米' : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contentBottom">
            <!-- 实时故障 -->
            <div class="contentLeft module">
              <div class="moduleTitle">
                <div class="leftLine"></div>
                <div class="title">实时故障</div>
              </div>
              <div class="moduleContent">
                <el-table :data="tableData">
                  <el-table-column prop="code" label="国标/地标映射" width="140" align="center"></el-table-column>
                  <el-table-column prop="desc" label="国标地标故障描述"></el-table-column>
                </el-table>
              </div>
            </div>
            <div class="contentRight module">
              <div class="moduleTitle">
                <div :class="[currentTab1 === 3 ? 'currentTab' : '']" @click="currentTab1 = 3">故障记录</div>
                <div :class="[currentTab1 === 1 ? 'currentTab' : '']" @click="currentTab1 = 1">急修记录</div>
                <div :class="[currentTab1 === 2 ? 'currentTab' : '']" @click="currentTab1 = 2">维保记录</div>
              </div>
              <div v-if="currentTab1 === 1" class="moduleContent" style="padding: 0;">
                <el-table :data="elevatorInfo.workOrderRecordVos">
                  <el-table-column prop="workOrderNo" label="工单编号" width="112" align="center"></el-table-column>
                  <el-table-column prop="alarmTime" label="发生时间"></el-table-column>
                </el-table>
              </div>
              <div v-if="currentTab1 === 2" class="moduleContent" style="padding: 0;">
                <el-table :data="elevatorInfo.maintRecordVos">
                  <el-table-column prop="maintDate" label="保养日期" width="112" align="center"></el-table-column>
                  <el-table-column prop="maintStatus" align="center" label="状态">
                    <template v-slot="scope">
                      <el-tag v-if="scope.row.maintStatus === 0" type="danger"> {{ $l("maintWorkOrder.invalid", "作废")
                        }}</el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 1">
                        {{ $l("maintWorkOrder.inPlan", "计划中") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 2" type="warning">
                        {{ $l("maintWorkOrder.notAccepted", "未接受") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 3">
                        {{ $l("maintWorkOrder.accepted", "已接受") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 4">
                        {{ $l("maintWorkOrder.signedIn", "已签到") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                        {{ $l("maintWorkOrder.completed", "已完成") }}
                      </el-tag>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="currentTab1 === 3" class="moduleContent" style="padding:0;">
                <el-table :data="elevatorInfo.faultRecordVos">
                  <el-table-column prop="faultCode" label="原始故障代码" width="140" align="center"></el-table-column>
                  <el-table-column prop="gbFaultCode" label="国标/地标映射" width="120" align="center"></el-table-column>
                  <el-table-column prop="faultDesc" label="国标地标故障描述"></el-table-column>
                  <el-table-column prop="faultTime" label="故障时间" width="160" align="center"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSpeed: 0,
      wsUrl: window.config.wsUrl,
      lockReconnect: false,
      reconnectInterval: 3000,
      reconnectNum: 3,
      useUnitElevators: [],
      toggleModalShow: false,
      elevatorInfo: {
        elevator: {},
      },
      connectState: "",
      webSocketInfo: {
        Ele_GB_Fault_Codes: [],
        Run_Times: -1,
        Run_Door: -1,
        Run_Wire_Rope: -1,
      },
      currentTab: 1,
      currentTab1: 3,
      faultDescription: "",
      tableData:[]
    };
  },

  watch: {
    // 监听电梯是否在运行
    "webSocketInfo.Ele_Run_State": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === 1) {
            this.animateValue(this.elevatorInfo.elevator.ratedSpeed);
          } else {
            this.animateValue2(this.elevatorInfo.elevator.ratedSpeed);
          }
        }
      },
    },

    "webSocketInfo.Ele_GB_Fault_Codes": {
      handler(newVal, oldVal) {
        if(newVal.length && this.elevatorInfo.elevator.faultId) {
          let arr = newVal.split(",");
          const params = {
            faultId:this.elevatorInfo.elevator.faultId,
            gbFaultCodes:arr
          };
          this.$http.post("faults/getByCode",params).then(res => {
            this.tableData = res.data || [];
          });
        }
      },
    }
  },

  methods: {
    animateValue(ratedSpeed) {
      this.currentSpeed = 0;
      const targetValue = ratedSpeed * 100;
      const increment = 35;
      const interval = setInterval(() => {
        if (this.currentSpeed + increment < targetValue) {
          this.currentSpeed += increment;
        } else {
          this.currentSpeed = targetValue;
          clearInterval(interval);
        }
      }, 600);
    },

    animateValue2(ratedSpeed) {
      let maxSpeed = ratedSpeed * 100;
      const increment = 35;
      const interval = setInterval(() => {
        if (maxSpeed - increment > 0) {
          maxSpeed -= increment;
          this.currentSpeed = maxSpeed;
        } else {
          this.currentSpeed = 0;
          clearInterval(interval);
        }
      }, 600);
    },

    changeElevator(id) {
      this.ws.close();
      this.initPage(id);
    },

    // 页面初始化
    init(data) {
      this.initPage(data.elevator.id);
    },

    // 获取电梯信息
    initPage(elevatorId) {
      this.$api.getById("singleLadderMonitor", elevatorId).then(res => {
        this.elevatorInfo = res.data;
        this.getList();
        this.createWs();
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },

    // 获取相同使用单位的电梯列表
    getList() {
      let params = {
        "pageNo": 1,
        "useUnitId": this.elevatorInfo.elevator.useUnitId,
        "pageSize": 100,
      };
      this.$api.getList("singleLadderMonitor", params).then(res => {
        this.useUnitElevators = res.data.records;
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },

    // 初始化webSocket
    createWs() {
      this.connectState = "开始连接";
      try {
        this.ws = new WebSocket(this.wsUrl);
        this.initEventHandle();
      } catch (e) {
        this.reconnectWs();
      }
    },

    // 获取webSocket返回的信息
    initEventHandle() {
      this.ws.onclose = () => {
        this.connectState = "连接断开";
      };
      this.ws.onerror = () => {
        this.connectState = "连接异常";
        this.reconnectWs();
      };
      this.ws.onopen = () => {
        this.connectState = "连接中...";
        let authProto = {
          t: "B00",
          d: {
            regCode: this.elevatorInfo.elevator.regNo,
          },
        };
        this.ws.send(JSON.stringify(authProto)); // 发送认证信息
      };
      this.ws.onmessage = (event) => {
        try {
          let proto = JSON.parse(event.data);
          if (proto.t === "A05" || proto.t === "A09") {
            this.reconnectNum = 3;
          }
          if (proto.t === "A09") {
            this.webSocketInfo = proto.d;
            if(this.webSocketInfo.Ele_GB_Fault_Codes) {
              this.webSocketInfo.Ele_GB_Fault_Codes = this.webSocketInfo.Ele_GB_Fault_Codes.join(",");
            } else {
              this.webSocketInfo.Ele_GB_Fault_Codes = [];
            }
            this.connectState = "连接正常";
          }
        } catch (e) {
          console.log(e);
        }
      };
    },

    // 重连
    reconnectWs() {
      if (!this.lockReconnect) {
        this.lockReconnect = true;
        // 没连接上会一直重连，设置延迟避免请求过，自动重连三次
        setTimeout(() => {
          if (this.reconnectNum > 0) {
            this.reconnectNum--;
            this.createWs();
            this.lockReconnect = false;
          }
        }, this.reconnectInterval);
      }
    },

    getHM(time) {
      let day = parseInt(time / 60 / 60 / 24);
      let hour = parseInt(time / 60 / 60 % 24);
      let min = parseInt(time / 60 % 60);
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      min = min > 9 ? min : "0" + min;
      return (time / 60 / 60).toFixed(2) + "小时";
    },

    getHMS(time) {
      let day = parseInt(time / 60 / 60 / 24);
      let hour = parseInt(time / 60 / 60 % 24);
      let min = parseInt(time / 60 % 60);
      let sec = parseInt(time % 60);
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      return day + "天" + hour + "时" + min + "分" + sec + "秒";
    },
  }
};
</script>

<style lang="scss" scoped>
.mainContent {
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  padding-right: 32px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    font-size: 12px;
    color: #4d4d4d;
  }

  .main {
    flex: 1;
    display: flex;
    min-height: 0;

    .mainLeft {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 20%;
      min-width: 320px;

      .elevatorName {
        font-size: 20px;
        color: #0747fd;
        padding: 10px 0 0 32px;
        min-height: 23px;
      }

      .model {
        flex: 1;
        position: relative;

        .modelImg {
          position: absolute;
          top: 50px;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: space-around;
        }
      }

      .toggle {
        width: 64px;
        height: 64px;
        background-color: #fff;
        border-radius: 32px;
        position: absolute;
        left: 32px;
        bottom: 32px;
        box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.2);
        font-size: 14px;
        color: #0747fd;
        text-align: center;
        line-height: 64px;
        cursor: pointer;

        &.toggleActive {
          background-color: #0747fd;
          color: #fff;
        }
      }

      .toggleModal {
        max-height: 352px;
        background-color: #fff;
        position: absolute;
        bottom: 116px;
        left: 32px;
        border-radius: 12px;
        overflow: auto;
        right: 32px;

        ul {
          list-style: none;
          padding: 0 16px;

          li {
            line-height: 32px;
            font-size: 14px;
            color: #808080;
            cursor: pointer;

            &:hover {
              background-color: #f4f8ff;
            }
          }
        }
      }
    }

    .mainRight {
      flex: 1;
      min-width: 1200px;

      .mainRightContent {
        height: 100%;
        padding-bottom: 57px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        // 每个模块的共同样式
        .module {
          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;

          .moduleTitle {
            height: 48px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            padding: 0 16px;
            box-sizing: border-box;
            color: #4d4d4d;

            .leftLine {
              width: 4px;
              height: 16px;
              background-color: #0747FD;
              border-radius: 100px;
              margin-right: 8px;
            }

            .title {
              flex: 1;
            }
          }

          .moduleContent {
            flex: 1;
            overflow: auto;
          }
        }

        .contentTop {
          height: 56%;
          display: flex;
          justify-content: space-between;
        }

        .contentBottom {
          flex: 1;
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          min-height: 0;
        }

        .contentLeft {
          flex: 1;
          display: flex;

          // 实时数据
          .liveData {
            width: 44.3%;
            border: 1px solid #0747FD;
            overflow: hidden;

            .moduleTitle {
              background-color: #0747FD;
              color: #fff;

              .leftLine {
                background-color: #fff;
              }
            }

            .moduleContent {
              padding: 15px 28px;
              position: relative;

              .liveDataItem {
                border-bottom: 1px solid #f2f2f2;
                display: flex;
                height: 41px;
                align-items: center;

                .liveDataTitle {
                  width: 56px;
                  font-size: 14px;
                  color: #808080;
                  margin-right: 16px;

                  &.floorAction {
                    background-color: #00C291;
                    border-radius: 100px;
                    color: #fff;
                    text-align: center;
                    line-height: 24px;
                    font-size: 12px;
                  }
                }

                .liveDataContent {
                  flex: 1;
                  color: #0747FD;
                  font-size: 18px;

                  .floor {
                    margin-right: 3px;
                    color: #1a1a1a;
                    font-size: 14px;
                  }
                }
              }

              // 重新连接
              .reconnect {
                background: rgb(255, 255, 255, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                .reconnectBtn {
                  width: 110px;
                  background-color: #0747FD;
                  border-radius: 100px;
                  color: #fff;
                  text-align: center;
                  padding: 8px 0;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 14px;
                  cursor: pointer;
                }
              }
            }
          }

          // 电梯状态
          .elevatorStatus {
            flex: 1;
            margin-left: 16px;

            .moduleContent {
              padding: 15px 28px;
            }
          }

          // 实时故障
          .liveFault {
            display: flex;
            font-size: 14px;
            color: #1a1a1a;
          }
        }

        .contentRight {
          width: 50%;
          margin-left: 16px;

          .moduleTitle {
            padding: 0 28px;

            div {
              line-height: 47px;
              margin-right: 29px;
              cursor: pointer;
            }
          }

          .moduleContent {
            padding: 15px 30px;
            font-size: 14px;
            color: #1a1a1a;

            .elevatorInfo {
              display: flex;
              margin-bottom: 13px;

              .elevatorInfoLeft {
                color: #808080;
                width: 120px;
              }

              .elevatorInfoRight {
                flex: 1;
                color: #1a1a1a;
              }
            }
          }
        }
      }
    }
  }

  .borderBottomNone {
    border-bottom: none !important;
  }

  .currentTab {
    color: #0747FD;
    border-bottom: 1px solid #0747FD;
  }
}
</style>

<style lang="scss">
.liftMonitor {
  .el-radio {
    width: 50%;
    margin-right: 0;
    margin-bottom: 10px;
    cursor: default !important;

    .el-radio__input {
      cursor: default !important;
    }

    .el-radio__label {
      cursor: default !important;
      color: #1a1a1a !important;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #0747fd;
      background-color: #0747fd;
    }

    .el-radio__input.is-checked .el-radio__inner::after {
      cursor: default !important;
      background-color: #fff;
    }

    .el-radio__input.is-disabled .el-radio__inner {
      cursor: default !important;
    }

    .el-radio__input.is-checked .el-radio__inner {
      cursor: default !important;
    }
  }

  .el-table::before {
    height: 0;
  }
}
</style>
