<template>
  <div v-loading="loading" style="height: 100%;">
    <lift-monitor v-if="elevatorType === 1 && !futi" ref="monitor"></lift-monitor>
    <escalator-monitor v-else-if="elevatorType === 1 && futi" ref="monitor"></escalator-monitor>
    <lift-monitor v-if="elevatorType === 4 && !futi" ref="monitor"></lift-monitor>
  </div>
</template>
<script>
import LiftMonitor from "./LiftMonitor.vue";
import EscalatorMonitor from "./EscalatorMonitor.vue";

export default {
  components: { EscalatorMonitor, LiftMonitor },
  data() {
    return {
      loading: true,
      futi: false,
      elevatorType: 0,//1升降梯、协议，
      //2扶梯，
      //3升降梯、传感器，
      //4扶梯、传感器
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$api.getById("singleLadderMonitor", this.$route.params.elevatorId).then(res => {
        this.loading = false;
        let elevator = res.data.elevator;
        let typeCode = elevator.elevatorProductTypeCode;
        //let monitorType = elevator.monitorType || 1;
        this.elevatorType = elevator.monitorType || 1;

        if (this.elevatorType == 3) {
          this.elevatorType = 1;
        }

        if (typeCode === "3310" || typeCode === "3320") {
          this.futi = true;
        }

        //console.log(res.data);
        //alert(this.elevatorType + "___" + typeCode);

        //1升降梯、协议，2扶梯，3升降梯、传感器，4扶梯、传感器
        // if (typeCode === "3310" || typeCode === "3320") {
        //   this.elevatorType = monitorType === 1 ? 2 : 4;
        // } else {
        //   //中移传感器 用我们的 界面
        //   this.elevatorType = (monitorType === 1 || monitorType === 3) ? 1 : 3;
        //   //alert(monitorType + "__hello__" + this.elevatorType);
        // }
        this.$nextTick(() => {
          this.$refs.monitor.init(res.data);
        });
      }).catch((e) => {
        this.loading = false;
        console.error(e);
        this.$message.error("获取数据失败");
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
